import { defineStore } from 'pinia';

import messagePlansService from '@/services/message-plans';
import type { BaseRemainingMessagesCountResponse } from '@/services/message-plans';

type State = {
  message_plan: {
    total_remaining_message_count:
      | BaseRemainingMessagesCountResponse['total_remaining_message_count']
      | null;
    is_alert_remaining_count: boolean;
    expired_alert_message_plan:
      | BaseRemainingMessagesCountResponse['expired_alert_message_plan']
      | null;
  };
};

export const useMessagePlanStore = defineStore('messagePlan', {
  state: (): State => ({
    message_plan: {
      total_remaining_message_count: null,
      is_alert_remaining_count: false,
      expired_alert_message_plan: null,
    },
  }),
  actions: {
    async fetchRemainingMessagesCount() {
      const { data } = await messagePlansService.fetchRemainingMessagesCount();
      this.message_plan.total_remaining_message_count =
        data.total_remaining_message_count;
      this.message_plan.is_alert_remaining_count =
        data.is_alert_remaining_count;
      this.message_plan.expired_alert_message_plan =
        data.expired_alert_message_plan;
    },
  },
});
