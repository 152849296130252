import graduatedService from '@/services/graduateds';
import store from '@/store';

const state = {
  selectedGraduatedId: null,
  graduateds: [],
};

const mutations = {
  SET_GRADUATES(oldState, graduateds) {
    Object.assign(oldState, { graduateds });
  },
};

const getters = {
  selectedGraduatedId: () => store.getters['staff/selectedGraduatedId'],
  selectedGraduatedYear: async currentState =>
    currentState.graduateds.find(
      graduated => graduated.id === store.getters['staff/selectedGraduatedId'],
    ),
  graduateds: currentState => currentState.graduateds,
};

const actions = {
  async FETCH({ commit }) {
    await graduatedService.fetchGraduateds().then(response => {
      commit('SET_GRADUATES', response);
    });
  },
};

export default {
  state,
  mutations,
  getters,
  actions,
  namespaced: true,
};
