<template>
  <nav class="global-nav">
    <ul class="nav-list" default-active="/home" :router="true">
      <router-link
        v-slot="{ isActive, navigate }"
        :to="{ name: 'Home' }"
        custom
      >
        <li
          class="item"
          :class="{ active: isActive }"
          @click="
            clickGlobalMenu($event);
            postPottosEvent(65);
            navigate();
          "
        >
          <img class="icon" src="@/assets/img/icon_home.svg" />
          ホーム
        </li>
      </router-link>
      <router-link
        v-slot="{ isActive, navigate }"
        :to="{ name: 'DashboardDefault' }"
        custom
      >
        <li
          class="item"
          :class="{ active: isActive }"
          @click="
            clickGlobalMenu($event);
            postPottosEvent(1);
            navigate();
          "
        >
          <img class="icon" src="@/assets/img/dashboard_blue.svg" />
          ダッシュボード
        </li>
      </router-link>
      <li
        class="accordion-list"
        :class="{
          'is-open': isOpenAccordion,
          'is-active': isApplicantsPage,
        }"
        @click="postPottosEvent(2)"
      >
        <p class="accordion-head" @click="isOpenAccordion = !isOpenAccordion">
          <img class="icon" src="@/assets/img/man_blue.svg" />
          応募者管理
          <img class="arrow_icon" src="@/assets/img/arrow_sidebar.svg" />
        </p>
        <ul>
          <router-link
            v-slot="{ isActive, navigate }"
            :to="{ name: 'ApplicantsOverview', params: { page: 1 } }"
            custom
          >
            <li
              :class="{
                active: isActive || isApplicantListPage,
              }"
              @click="
                $emit('toggleGlobalMenu');
                navigate();
              "
            >
              応募者一覧
            </li>
          </router-link>
          <router-link
            v-slot="{ isActive, navigate }"
            :to="{ name: 'ApplicantsUnreadPage' }"
            custom
          >
            <li
              :class="{ active: isActive }"
              @click="
                $emit('toggleGlobalMenu');
                postPottosEvent(36);
                navigate();
              "
            >
              新着情報
            </li>
          </router-link>
        </ul>
      </li>
      <router-link
        v-slot="{ isActive, navigate }"
        :to="{ name: 'Calendar' }"
        custom
      >
        <li
          class="item"
          :class="{ active: isActive }"
          @click="
            clickGlobalMenu($event);
            postPottosEvent(3);
            navigate();
          "
        >
          <img class="icon" src="@/assets/img/icon_calendar.svg" />
          カレンダー
        </li>
      </router-link>
      <router-link
        v-slot="{ isActive, navigate }"
        :to="{ name: 'SelectionsOverview' }"
        custom
      >
        <li
          class="item"
          :class="{ active: isActive }"
          @click="
            clickGlobalMenu($event);
            navigate();
          "
        >
          <img class="icon" src="@/assets/img/two_men_blue.svg" />
          選考
        </li>
      </router-link>
      <router-link
        v-slot="{ isActive, navigate }"
        :to="{ name: 'Announce' }"
        custom
      >
        <li
          class="item"
          :class="{ active: isActive || isAnnouncePage }"
          @click="
            clickGlobalMenu($event);
            postPottosEvent(4);
            navigate();
          "
        >
          <img class="icon" src="@/assets/img/send_mail_circle_blue.svg" />
          応募者へ通知
        </li>
      </router-link>
      <router-link
        v-slot="{ isActive, navigate }"
        :to="{ name: 'TagGroups' }"
        custom
      >
        <li
          class="item"
          :class="{ active: isActive }"
          @click="
            clickGlobalMenu($event);
            postPottosEvent(5);
            navigate();
          "
        >
          <img class="icon" src="@/assets/img/flag_blue.svg" />
          フラグ管理
        </li>
      </router-link>
      <router-link
        v-slot="{ isActive, navigate }"
        :to="{ name: 'Configs' }"
        custom
      >
        <li
          class="item"
          :class="{ active: isActive }"
          @click="
            clickGlobalMenu($event);
            postPottosEvent(6);
            navigate();
          "
        >
          <img class="icon" src="@/assets/img/settings_blue.svg" />
          設定
        </li>
      </router-link>
      <li class="item position" @click="postPottosEvent(7)">
        <a href="https://help.mochica.jp/" target="_blank"></a>
        <img class="icon" src="@/assets/img/help_blue.svg" />
        ヘルプページ
      </li>
    </ul>
    <div class="line-consumption">
      <div class="quota">当月のLINEメッセージ数</div>
      <div>
        <div
          v-if="$store.getters['company/lineConsumption'] !== null"
          class="quota_count"
        >
          <span class="quota_count_left">
            {{ $store.getters['company/lineConsumption'].total_usage }}
          </span>
          <span class="delimiter">/</span>
          <span>{{ $store.getters['company/lineConsumption'].limited }}</span>
        </div>
        <div v-else>取得失敗</div>
      </div>
    </div>
    <div
      v-if="
        $store.getters['company/lineConsumption'] &&
        $store.getters['company/lineConsumption'].isShowWarningMsg
      "
      class="line-consumption-warning"
    >
      <p>残り通数が少なくなっています</p>
    </div>
    <div
      v-if="$store.getters['company/company'].is_line_cooperate_user"
      class="block-message-plan"
    >
      <router-link :to="{ name: 'Plan' }" class="message-plan-total">
        <div class="message-plan-total-title">残りLINE通知メッセージ数</div>
        <div class="message-plan-total-count">
          <span class="message-plan-total-count-text">
            {{ messagePlan.total_remaining_message_count }}
          </span>
          <span class="message-plan-total-count-icon">
            <img
              src="@/assets/img/arrow_right_blue.svg"
              alt="arrow_right_blue"
            />
          </span>
        </div>
      </router-link>
      <div
        v-if="
          messagePlan.is_alert_remaining_count ||
          messagePlan.expired_alert_message_plan
        "
        class="message-plan-alert"
      >
        <div
          v-if="messagePlan.is_alert_remaining_count"
          class="message-plan-alert-text"
        >
          残り通数が少なくなっています
        </div>
        <div
          v-if="messagePlan.expired_alert_message_plan"
          class="message-plan-alert-text"
        >
          {{
            moment(messagePlan.expired_alert_message_plan.end_date).format(
              'YYYY/MM/DD',
            )
          }}で
          <br />
          失効する通数があります
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import moment from 'moment';
import { computed, defineComponent, onBeforeMount, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import { postPottosEvent } from '@/utils/pottos';
import { useMessagePlanStore } from '@/stores/messagePlan';

export default defineComponent({
  name: 'Sidebar',
  emits: ['toggleGlobalMenu'],
  setup(props, context) {
    const route = useRoute();
    const messagePlanStore = useMessagePlanStore();
    const applicantRoutes = [
      'ApplicantsOverview',
      'ApplicantsOverviewDefault',
      'ApplicantsRegistration',
      'ApplicantsEmailRegistration',
      'ApplicantsDoubleCheck',
      'ApplicantsCsvDownload',
      'ApplicantsUnreadPage',
      'ApplicantsUnreadMessages',
      'ApplicantsSelectionReserves',
      'ApplicantsEnteredApplicants',
      'ApplicantsUploads',
      'ApplicantsRegistrationCsvMynavi',
      'ApplicantsRegistrationCsvRecnavi',
      'ApplicantsRegistrationCsvMochica',
    ];
    const applicantListRoutes = [
      'ApplicantsOverview',
      'ApplicantsOverviewDefault',
      'ApplicantsRegistration',
      'ApplicantsEmailRegistration',
      'ApplicantsDoubleCheck',
      'ApplicantsCsvDownload',
      'ApplicantsRegistrationCsvMynavi',
      'ApplicantsRegistrationCsvRecnavi',
      'ApplicantsRegistrationCsvMochica',
    ];
    const AnnounceRoutes = [
      'Richmessage',
      'RichmessageHistory',
      'Flagsurvey',
      'FlagsurveyHistory',
    ];
    const isOpenAccordion = ref(false);

    // computed
    const isApplicantsPage = computed(() => {
      return applicantRoutes.includes(route.name);
    });
    const isApplicantListPage = computed(() => {
      return applicantListRoutes.includes(route.name);
    });
    const isAnnouncePage = computed(() => {
      return AnnounceRoutes.includes(route.name);
    });

    // watch
    watch(route, () => {
      if (applicantRoutes.includes(route.name)) {
        isOpenAccordion.value = applicantRoutes.includes(route.name);
      }
    });

    // methods
    const clickGlobalMenu = () => {
      isOpenAccordion.value = false;
      context.emit('toggleGlobalMenu');
    };

    // lifecycle
    onBeforeMount(() => {
      isOpenAccordion.value = isApplicantsPage.value;
    });

    return {
      isOpenAccordion,
      isApplicantsPage,
      isApplicantListPage,
      isAnnouncePage,
      clickGlobalMenu,
      postPottosEvent,
      messagePlan: messagePlanStore.message_plan,
      moment,
    };
  },
});
</script>

<style lang="scss" scoped>
@import '@/assets/variables.scss';

.global-nav {
  display: flex;
  height: calc(100vh - 50px);
  flex-wrap: wrap;
  overflow: auto;
  align-content: flex-start;
  > .nav-list {
    width: 180px;
    font-size: 1.4rem;
    > .accordion-list {
      color: #333;
      border-bottom: 1px solid #efefef;

      > .accordion-head {
        position: relative;
        display: block;
        padding: 20px 10px 20px 15px;
        cursor: pointer;
        > .icon {
          max-width: 20px;
          max-height: 20px;
          margin-right: 7px;
          vertical-align: -5px;
          color: #1899d6;
        }

        > .arrow_icon {
          max-width: 20px;
          max-height: 20px;
          position: absolute;
          top: 50%;
          right: 15px;
          width: 12px;
          height: 12px;
          margin-top: -6px;
          transform: rotate(90deg);
          transition: transform 0.2s ease;
        }
      }
      .unread_count {
        display: inline-block;
        color: #fff;
        font-size: 0.6rem;
        min-width: 18px;
        min-height: 18px;
        background-color: #e83d86;
        padding: 4px 4px;
        margin-left: 6px;
        border-radius: 50%;
        cursor: pointer;
        font-weight: bold;
        text-align: center;
      }
      &:hover {
        background-color: #efefef;
      }

      > ul {
        height: 0;
        opacity: 0;
        visibility: hidden;
        background-color: #fff;
        transition: all 0.2s ease;

        > li {
          padding: 0 10px 0 45px;
          font-size: 1.1rem;
          cursor: pointer;
          transition: all 0.2s ease;

          &:hover {
            background-color: #eee;
          }

          &.active {
            color: #1899d6;
            font-weight: bold;
            background-color: #f2f9fc;
          }
        }
      }

      &.is-open {
        > .accordion-head {
          > .arrow_icon {
            transform: rotate(180deg);
          }
        }

        > ul {
          display: block;
          height: 80px;
          opacity: 1;
          visibility: visible;

          > li {
            display: flex;
            align-items: center;
            height: 40px;
            padding: 10px 10px 10px 45px;
          }
        }
      }

      &.is-active {
        > .accordion-head {
          color: #1899d6;
          font-weight: bold;
          background-color: #efefef;
        }
      }

      > a {
        top: 361px;
      }
    }
    > .item {
      padding: 20px 10px 20px 15px;
      border-bottom: 1px solid #efefef;
      cursor: pointer;
      &.position {
        position: relative;
      }
      &.active {
        color: #1899d6;
        font-weight: bold;
        background-color: #efefef;
      }

      &:hover {
        background-color: #efefef;
      }

      > .icon {
        max-width: 20px;
        max-height: 20px;
        margin-right: 7px;
        vertical-align: -4px;
        color: #1899d6;
      }

      &:nth-child(1) > .icon {
        vertical-align: -2px;
      }
      &:nth-child(2) > .icon {
        vertical-align: -2px;
      }

      > a {
        position: absolute;
        display: inline-block;
        height: 61px;
        width: 180px;
        top: 0;
        left: 0;

        > .icon {
          max-width: 20px;
          max-height: 20px;
          margin-right: 7px;
          vertical-align: -5px;
          color: #1899d6;

          .st0 {
            fill: #1899d6;
          }
        }
      }
    }
  }
  .line-consumption {
    padding: 20px 15px 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    font-size: 11px;
    color: #707070;
    .quota {
      font-weight: bold;
      margin-bottom: 10px;
    }
    .quota_count {
      .quota_count_left {
        color: $mochica_color;
        font-size: 20px;
      }
      font-weight: bold;
      font-size: 13px;
    }
    .delimiter {
      margin: 0 5px;
    }
  }

  .line-consumption-warning {
    margin: auto;
    padding: 10px;
    border: 1px solid #ffe0e0;
    border-radius: 4px;
    color: #de3131;
    background-color: #fef6f6;

    > p {
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: bold;
      font-size: $font_10;
    }
  }

  .block-message-plan {
    width: 100%;
  }

  .message-plan-total {
    display: block;
    margin: 10px 4px;
    background-color: $pale_blue;
    border-radius: 4px;
    border: 1px solid $pale_blue_border;
    padding: 14px 10px;
    font-size: $font_11;
    font-weight: bold;
    color: $base_text_color;

    &:hover {
      opacity: 0.7;
    }
  }

  .message-plan-total-title {
    text-align: center;
    letter-spacing: 0.05em;
    color: #333;
  }

  .message-plan-total-count {
    display: flex;
    margin-top: 12px;
    font-size: $font_20;
    font-weight: bold;
    color: $mochica_color;
  }

  .message-plan-total-count-text {
    flex: 1;
  }

  .message-plan-total-count-icon {
    margin-left: 4px;
    max-width: 100%;

    & > img {
      min-width: 8px;
      max-width: 8px;
    }
  }

  .message-plan-alert {
    margin: 0 4px 10px 4px;
  }

  .message-plan-alert-text {
    padding: 14px 8px;
    line-height: 1.4;
    background-color: $pale_red;
    color: $mochica_color_red;
    font-size: $font_11;
    font-weight: bold;
    margin-bottom: 6px;
    border: 1px solid $pale_red_border;
    border-radius: 4px;
  }
}
</style>
