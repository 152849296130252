export const MESSAGE_PLAN_KINDS = Object.freeze({
  '100': 100,
  '500': 500,
  '1000': 1000,
  '5000': 5000,
  '10000': 10000,
} as const);

export const MESSAGE_PLAN_STATUSES = Object.freeze({
  disabled: 0,
  enabled: 1,
} as const);

export const MESSAGE_PLAN_FIXED_END_MONTH = 18 as const;
