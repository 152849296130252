<script setup lang="ts">
import { onMounted } from 'vue';
import { useStore } from 'vuex';

import ModalBase from '@/components/ui/modals/components/ModalBase.vue';
import SendTargetApplicantsTable from '@/components/features/lineNotification/SendTargetApplicantsTable.vue';
import { CONTACT_TYPE_IDS } from '@/defines/applicant';

import { Applicant } from '@/types/applicant';
import { useMessagePlanStore } from '@/stores/messagePlan';

const props = withDefaults(
  defineProps<{ isVisible: boolean; applicants: Applicant[] }>(),
  {
    isVisible: false,
    applicants: () => [],
  },
);
defineEmits(['onClickCancel', 'onClickSettle']);

const store = useStore();

const targetApplicants = () =>
  props.applicants.filter(
    applicant => applicant.contactTypeId === CONTACT_TYPE_IDS.mail,
  );

const notTargetApplicants = () =>
  props.applicants.filter(
    applicant => applicant.contactTypeId === CONTACT_TYPE_IDS.line,
  );

const messagePlanStore = useMessagePlanStore();
const messagePlans = messagePlanStore.message_plan;

const isSubmitDisallowed = (): boolean => {
  const remainingCount = messagePlans.total_remaining_message_count ?? 0;
  if (remainingCount === 0) return true;
  return remainingCount < props.applicants.length;
};

onMounted(async () => {
  try {
    await messagePlanStore.fetchRemainingMessagesCount();
  } catch (error) {
    console.error(error);
    store.dispatch('notification/VISIBLE_NOTIFICATION', {
      message: '残りLINE通知メッセージの送信可能数取得に失敗しました',
      type: false,
    });
  }
});
</script>
<template>
  <modal-base
    :is-view="isVisible"
    :title="'LINE切り替え案内の一括送信'"
    :is-closable-click-outside="false"
    :is-view-close-button="false"
    class="modal-bulk-send-switch-line-announce"
  >
    <template #main>
      <div class="wrapper">
        <div class="inner-header">
          <p class="inner-header-text">
            一覧画面で選択した応募者に、LINE切り替え案内をLINE通知メッセージとメールで送信します。
            <br />
            電話番号が登録されていない応募者はLINE通知メッセージを受信できないため、メールのみを送信します。
          </p>
          <span class="inner-header-caption">
            ※応募者側の受信設定が済んでいない場合は、LINE通知メッセージの送信に失敗することがあります。
          </span>
        </div>
        <div class="contents">
          <div class="contents-top">
            <p class="contents-title">
              <span class="contents-title-text">対象件数：</span>
              <strong class="contents-title-number">
                {{ targetApplicants().length }}
              </strong>
              <span class="contents-title-text">名</span>
            </p>
            <send-target-applicants-table
              v-if="targetApplicants().length > 0"
              :applicants="targetApplicants()"
              :is-visible="isVisible"
            />
          </div>
          <div v-if="notTargetApplicants().length > 0" class="contents-bottom">
            <div class="contents-title">
              <p class="contents-title-caption">
                <span>以下の応募者は【LINE登録済み】のため</span>
                <span class="caption-danger">
                  LINE切り替え案内を送信できません。
                </span>
              </p>
              <send-target-applicants-table
                :applicants="notTargetApplicants()"
                :is-visible="isVisible"
              />
            </div>
          </div>
        </div>
        <div class="btn-col2">
          <button
            type="button"
            class="btn btn-unavailable"
            @click="$emit('onClickCancel')"
          >
            キャンセル
          </button>
          <button
            type="button"
            class="btn btn-availability submit"
            :disabled="targetApplicants().length === 0 || isSubmitDisallowed()"
            @click="
              $emit(
                'onClickSettle',
                targetApplicants().map(v => v.id),
              )
            "
          >
            LINE切り替え案内を送信する
          </button>
        </div>
        <div class="message-plan-total">
          <div v-if="isSubmitDisallowed()" class="message-plan-alert-text">
            残り送信可能数をオーバーしています。
          </div>
          <div
            class="message-plan-total-text"
            :class="{
              'message-plan-alert-text': isSubmitDisallowed(),
            }"
          >
            LINE通知メッセージの残り送信可能数は
            <span>
              {{ messagePlans.total_remaining_message_count || 0 }}
            </span>
            通です。
          </div>
        </div>
      </div>
    </template>
  </modal-base>
</template>
<style scoped lang="scss">
@import '@/assets/variables.scss';
@include tableStyle;

.wrapper {
  padding: 10px;
}
.inner-header {
  margin-bottom: 20px;
  line-height: 1.8;
}
.inner-header-text {
  margin-bottom: 8px;
  font-size: $font_14;
}
.inner-header-caption {
  font-size: $font_12;
  color: #777;
}
.contents {
  margin-bottom: 6px;
}
.contents-top {
  margin-bottom: 30px;
}
.contents-bottom {
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid $mochica_color_gray2;
}
.contents-title {
  margin-bottom: 6px;
}
.contents-title-text {
  font-weight: bold;
  font-size: $font_14;
}
.contents-title-number {
  font-weight: bold;
  font-size: $font_24;
}
.contents-title-caption {
  margin-bottom: 12px;
  font-size: $font_14;
  font-weight: bold;
}
.caption-danger {
  color: $mochica_color_red;
}
.btn-col2 {
  .btn {
    &:disabled {
      opacity: 0.5;
      pointer-events: none;
    }
  }
}

.message-plan-total {
  text-align: center;
  font-size: $font_14;
  font-weight: bold;
  margin-top: 26px;
}

.message-plan-alert-text {
  color: $mochica_color_red;
}
.message-plan-total-text {
  & > span {
    font-size: $font_20;
  }
}
</style>
