import { createStore } from 'vuex';
import applicant from '@/store/modules/applicant';
import plan from '@/store/modules/plan';
import auth from '@/store/modules/auth';
import graduateds from '@/store/modules/graduateds';
import staff from '@/store/modules/staff';
import company from '@/store/modules/company';
import page from '@/store/modules/page';
import notification from '@/store/modules/notification';
import modalApplicants from '@/store/modules/modal-applicants';
import ui from '@/store/modules/ui/index';
import announce from '@/store/modules/announce';
import searchFilter from '@/store/modules/search-filter';

const store = createStore({
  modules: {
    applicant,
    plan,
    auth,
    graduateds,
    staff,
    company,
    page,
    notification,
    modalApplicants,
    ui,
    announce,
    searchFilter,
  },
});

export default store;
