<template>
  <div v-if="$store.getters['graduateds/graduateds']">
    <navbar
      :is-global-menu="isGlobalMenu"
      @toggleGlobalMenu="toggleGlobalMenu"
    />
    <div
      v-if="lineConsumption && lineConsumption.isShowWarningMsg"
      class="remaining-line-warning-msg"
    >
      <img src="@/assets/img/icon_warning.svg" />
      <p>
        LINEメッセージの残り通数が少なくなっています。プランに対するご相談は担当者までご連絡ください。
      </p>
    </div>
    <div class="main-wrapper">
      <main class="flexbox width-app-limit">
        <div
          class="sidebar"
          :class="{
            '-open': isGlobalMenu,
          }"
          :style="{
            paddingTop: getPaddingTop,
          }"
        >
          <sidebar @toggleGlobalMenu="toggleGlobalMenu" />
        </div>
        <div
          class="contents"
          :style="{
            paddingTop: getPaddingTop,
            paddingLeft: '180px',
          }"
        >
          <router-view />
          <div v-if="!$store.getters['page/isPageLoaded']">
            <page-loader />
          </div>
        </div>
      </main>
    </div>
    <modal-applicants />
    <notification />
    <application-error />
    <top-modal-dialog />
    <modal-remaining-line-warning
      :is-visible="
        lineConsumption &&
        lineConsumption.isShowWarningMsg &&
        !hasClosedModalThisMonth
      "
      @close="closeLineWarningModal"
    />
  </div>
  <p v-else>卒年データロード中</p>
</template>

<script>
import { defineComponent, ref, computed } from 'vue';
import { useStore } from 'vuex';

import Notification from '@/components/ui/notification/components/Notification';
import PageLoader from '@/components/ui/loaders/components/PageLoader';
import TopModalDialog from '@/components/ui/modals/components/TopModalDialog';
import Navbar from '@/components/page/top/components/Navbar';
import Sidebar from '@/components/page/top/components/Sidebar';
import ApplicationError from '@/components/page/top/components/ApplicationError';
import ModalApplicants from '@/components/page/top/components/ModalApplicants';
import ModalRemainingLineWarning from '@/components/page/top/components/ModalRemainingLineWarning';

export default defineComponent({
  name: 'Top',
  components: {
    Notification,
    PageLoader,
    TopModalDialog,
    Navbar,
    Sidebar,
    ApplicationError,
    ModalApplicants,
    ModalRemainingLineWarning,
  },
  setup(props, context) {
    const HEADER_HEIGHT = 50;
    const LINE_ALERT_HEIGHT = 50;
    const isGlobalMenu = ref(false);
    const lastModalClosedDate = localStorage.getItem('lastModalClosedDate');
    const hasClosedModalThisMonth = ref(
      lastModalClosedDate &&
        new Date(lastModalClosedDate).getMonth() === new Date().getMonth(),
    );
    const toggleGlobalMenu = () => {
      isGlobalMenu.value = !isGlobalMenu.value;
    };
    const store = useStore();
    const lineConsumption = computed(
      () => store.getters['company/lineConsumption'],
    );
    // 当月のLINE通数アラートが表示される場合はアラートの高さ分上方向のpaddingを追加する
    const getPaddingTop = computed(
      () =>
        `${
          lineConsumption.value && lineConsumption.value.isShowWarningMsg
            ? HEADER_HEIGHT + LINE_ALERT_HEIGHT
            : HEADER_HEIGHT
        }px`,
    );
    const closeLineWarningModal = () => {
      localStorage.setItem('lastModalClosedDate', new Date().toISOString());
      hasClosedModalThisMonth.value = true;
    };

    return {
      isGlobalMenu,
      toggleGlobalMenu,
      lineConsumption,
      hasClosedModalThisMonth,
      closeLineWarningModal,
      getPaddingTop,
    };
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';

.main-wrapper {
  // height: 100vh;
  background-color: #e2e2e2;
}

.sidebar {
  position: fixed;
  z-index: 40;
  top: 0;
  left: 0;
  width: 180px;
  height: 100vh;
  background-color: #fff;
  transition: left 0.2s ease-out;
}

.contents {
  width: 100%;
}

.remaining-line-warning-msg {
  display: flex;
  padding-left: 36px;
  align-items: center;
  position: fixed;
  z-index: 50;
  top: 50px;
  left: 0;
  width: 100%;
  height: 50px;
  background: #fdf0f0;
  color: #de3131;
  font-weight: bold;

  > img {
    margin-right: 0.5rem;
  }
}

@media (max-width: ($media_query_tablet)) {
  .main-wrapper {
    height: 100%;
  }
  .contents {
    width: 100%;
    padding: 50px 0 0;
  }
  .sidebar {
    left: -180px;
  }
  .sidebar.-open {
    left: 0;
  }
}
</style>
