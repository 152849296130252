import httpClient from '@/http-client';
import { MessagePlanKind, MessagePlanStatus } from '@/types/messagePlans';

type BaseApiResponse<T> = {
  data: T & {
    success: boolean;
    message: string;
  };
};

export type BaseMessagePlansResponse = {
  message_plans: {
    id: number;
    company_id: number;
    disabled: boolean;
    kind: MessagePlanKind;
    status: MessagePlanStatus;
    plan_message_count: number;
    remaining_message_count: number;
    term: string;
    has_expired_threshold: boolean;
    has_max_count_threshold: boolean;
  }[];
};

export type FetchMessagePlan = {
  params: {
    company_id: number;
  };
  response: BaseApiResponse<BaseMessagePlansResponse>;
};

export type PostMessagePlan = {
  params: {
    message_plan: {
      company_id: number;
      start_date: string;
      kind: MessagePlanKind;
      status: MessagePlanStatus;
    };
  };
  response: BaseApiResponse<void>;
};

export type BulkUpdateMessagePlans = {
  params: {
    company_id: number;
    message_plans: {
      id: number;
      status: MessagePlanStatus;
    }[];
  };
  response: BaseApiResponse<BaseMessagePlansResponse>;
};

export type BaseRemainingMessagesCountResponse = {
  total_remaining_message_count: number;
  is_alert_remaining_count: boolean;
  expired_alert_message_plan: {
    id: number;
    company_id: number;
    kind: MessagePlanKind;
    status: MessagePlanStatus;
    consumption_count: number;
    start_date: string;
    end_date: string;
    has_expired_threshold: boolean;
    has_max_count_threshold: boolean;
  } | null;
};

export type FetchRemainingMessagesCount = {
  response: BaseApiResponse<BaseRemainingMessagesCountResponse>;
};

class MessagePlansManager {
  private static instance: MessagePlansManager;

  private constructor() {}

  static getInstance(): MessagePlansManager {
    if (!MessagePlansManager.instance) {
      MessagePlansManager.instance = new MessagePlansManager();
    }
    return MessagePlansManager.instance;
  }

  async fetchMessagePlans(
    params: FetchMessagePlan['params'],
  ): Promise<FetchMessagePlan['response']> {
    const response = await httpClient.get<FetchMessagePlan['response']['data']>(
      '/message_plans',
      { params },
    );
    return { data: response.data };
  }

  async postMessagePlan(
    params: PostMessagePlan['params'],
  ): Promise<PostMessagePlan['response']> {
    const response = await httpClient.post<PostMessagePlan['response']['data']>(
      '/message_plans',
      params,
    );
    return { data: response.data };
  }

  async bulkUpdateMessagePlans(
    params: BulkUpdateMessagePlans['params'],
  ): Promise<BulkUpdateMessagePlans['response']> {
    const response = await httpClient.post<
      BulkUpdateMessagePlans['response']['data']
    >('/message_plans/bulk/update', params);
    return { data: response.data };
  }
  async fetchRemainingMessagesCount(): Promise<
    FetchRemainingMessagesCount['response']
  > {
    const response = await httpClient.get<
      FetchRemainingMessagesCount['response']['data']
    >('/message_plans/remaining_message_count');
    return { data: response.data };
  }
}

export default MessagePlansManager.getInstance();
