import httpClient from '@/http-client';
import Staff from '@/models/staff';
import store from '@/store';

const StaffsManager = function _StaffsManager() {
  const manager = Object.create(StaffsManager.prototype);
  return manager;
};

StaffsManager.prototype = {
  async loginInit() {
    const res = await httpClient.post('/login/init');
    return res;
  },
  async loginCheck() {
    const res = await httpClient.post('/login/check');
    return res;
  },
  async inviteStaff(roleId, email) {
    const response = await httpClient.post('/invite', {
      invite: { role_id: roleId, email },
    });
    return response.data;
  },
  // staffs登録
  async registerStaffs(args) {
    // スタッフ登録
    const response = await httpClient.post('/register_staffs', args);
    return response;
  },
  // staffs関連ルート
  async fetchStaffs(excludeDeletedStaffs = false) {
    // スタッフ一覧取得
    const response = await httpClient.get(
      `/staffs?exclude_deleted_staffs=${excludeDeletedStaffs}`,
    );
    return response.data.staffs.map(Staff.createFromJSON);
  },
  async fetchStaff(staffId) {
    // スタッフ個別取得
    const response = await httpClient.get(`/staffs/${staffId}`);
    store.dispatch(
      'staff/SET_STAFF',
      Staff.createFromJSON(response.data.staff),
    );
    return response.data.staff;
  },
  async deleteStaff(staffId) {
    // スタッフの削除
    const response = await httpClient.delete(`/staffs/${staffId}`);
    return response.data;
  },
  async updateStaffRole(staffId, roleId) {
    // スタッフのロール更新
    const response = await httpClient.patch(`/staffs/${staffId}/role`, {
      role: { id: roleId },
    });
    return response.data;
  },
  async updateEmailPending(staffId, email) {
    // Email変更メール送信
    const response = await httpClient.patch(`/staffs/${staffId}/email`, {
      email,
    });
    return response.data;
  },
  async cancelEmailPending(staffId) {
    // Email変更キャンセル
    const response = await httpClient.patch(`/staffs/${staffId}/cancel_email`);
    return response.data;
  },

  // /me 自分の情報リソース
  async updateStaff(staffId, lastname, firstname, sign, tel) {
    // 自分の姓名・署名変更
    const response = await httpClient.patch('/me', {
      staff: { firstname, lastname, sign, tel },
    });
    return response.data;
  },
  async updatePassWord(staffId, current, ne, confirm) {
    // 自分のパスワード変更
    const response = await httpClient.patch('/me//password', {
      password: { current, new: ne, confirm },
    });
    return response.data;
  },
  async patchStaffSelectedGraduated(selectedGraduatedId) {
    // 選択中の卒年変更
    const res = await httpClient.patch('/me/selected_graduated', {
      graduated: { id: selectedGraduatedId },
    });
    return res;
  },
  async displaySetting(columnName, applicantListColumns, loadSelections) {
    // 表示項目設定
    const res = await httpClient.post('/me/display_setting', {
      targetColumn: columnName,
      columns: applicantListColumns,
      loadSelection: loadSelections,
    });
    return res.data;
  },
  async flagGroupSetting(columnName, applicantListFlagGroup) {
    // フラググループ選択設定
    const res = await httpClient.post('/me/flag_group_setting', {
      targetColumn: columnName,
      flagGroup: applicantListFlagGroup,
    });
    return res.data;
  },
  async updateTagGroupsOrder(params) {
    // フラグの並び替え
    const res = await httpClient.post('/me/flag_group_sort', params);
    return res.data;
  },
};

export default StaffsManager();
