<script setup lang="ts">
defineOptions({ inheritAttrs: false });

const props = defineProps<{
  modelValue: boolean | number;
  trueValue?: number | boolean;
  falseValue?: number | boolean;
  disabled?: boolean;
}>();

const emit = defineEmits(['update:modelValue']);

const updateModelValue = (event: Event) => {
  const checked = (event.target as HTMLInputElement).checked;
  emit(
    'update:modelValue',
    checked ? props.trueValue ?? true : props.falseValue ?? false,
  );
};
</script>

<template>
  <label class="switch-status" :class="{ disabled }">
    <input
      :checked="modelValue === (trueValue ?? true)"
      :value="modelValue"
      :disabled="disabled"
      v-bind="$attrs"
      type="checkbox"
      class="toggle-switch"
      name="toggle-switch"
      @change="updateModelValue"
    />
    <span class="slider round"></span>
  </label>
</template>

<style scoped lang="scss">
@import '@/assets/variables.scss';
$disabled-color: #d0d0d0;

.switch-status {
  position: relative;
  display: inline-block;
  width: 54px;
  height: 28px;

  input {
    display: none;

    &:checked + .slider {
      background-color: $mochica_color;
    }

    &:checked + .slider:before {
      -webkit-transform: translateX(26px);
      -ms-transform: translateX(26px);
      transform: translateX(26px);
    }

    &:focus + .slider {
      box-shadow: 0 0 1px $mochica_color_red;
    }
  }

  .slider {
    position: absolute;
    inset: 0;
    cursor: pointer;
    background-color: $mochica_color_red;
    -webkit-transition: 0.4s;
    transition: 0.4s;

    &:before {
      position: absolute;
      content: '';
      height: 20px;
      width: 20px;
      left: 4px;
      bottom: 4px;
      background-color: $white;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }

    &.round {
      border-radius: 34px;

      &:before {
        border-radius: 50%;
      }
    }
  }
  &.disabled {
    pointer-events: none;

    .slider,
    input:checked + .slider {
      background-color: $disabled-color;
    }
  }
}
</style>
