import { createRouter, createWebHistory } from 'vue-router';

// 認証ページ
import Top from '@/components/page/top/components/Top';
import Admin from '@/pages/admin';
import Home from '@/pages/home';
import Dashboard from '@/pages/dashboard/index';
import DashboardBasic from '@/components/page/dashboard/basic/components/DashboardBasic';
import DashboardEntry from '@/components/page/dashboard/entry/components/DashboardEntry';
import DashboardKind from '@/components/page/dashboard/kind/components/DashboardKind';
import Applicants from '@/pages/applicants/index';
import ApplicantsId from '@/pages/applicants/_id';
import ApplicantsUnreadPage from '@/pages/applicants/unreadpage/index';
import ApplicantsRegistration from '@/pages/applicants/registration/index';
import ApplicantsEmailRegistration from '@/pages/applicants/emailregist/index';
import ApplicantsDoubleCheck from '@/pages/applicants/doublecheck';
import ApplicantsCsvDownload from '@/pages/applicants/csv';
import Announce from '@/pages/announce/index';
import NoticeAnnounce from '@/pages/announce/noticeannounce';
import SelectionAnnounce from '@/pages/announce/selectionannounce';
import ResultAnnounce from '@/pages/announce/resultannounce';
import Richmessage from '@/pages/richmessage';
import RichmessageHistory from '@/pages/richmessage/richmessagehistory';
import Configs from '@/pages/configs';
import ConfigsPersonal from '@/pages/configs/personal';
import ConfigsCompany from '@/pages/configs/company';
import ConfigsStaffs from '@/pages/configs/staffs';
import ConfigsEntryformSetting from '@/pages/configs/entryformSetting';
import ConfigsSurvey from '@/pages/configs/survey';
import ConfigsFixphrase from '@/pages/configs/fixphrase';
import ConfigsPlan from '@/pages/configs/plan';
import ConfigsLinkSystem from '@/pages/configs/linkSystem';
import TagGroups from '@/pages/tagGroups/index';
import Calendar from '@/pages/calendar/';
import SelectionsOverview from '@/pages/selections';
import SelectionDetail from '@/pages/selections/_id';
import SelectionRegistration from '@/pages/selections/registration';
import ApplicantPrint from '@/pages/applincant_print/index';
import Flagsurvey from '@/pages/flagsurvey';
import FlagsurveyHistory from '@/pages/flagsurvey/flagsurveyhistory';
import Guide from '@/pages/guide';
import AutoActions from '@/pages/autoActions';
import AutoActionsDetail from '@/pages/autoActions/_id';
import AutoActionsCreate from '@/components/features/autoActions/Create.vue';
import AutoActionsEdit from '@/components/features/autoActions/Edit.vue';
import AutoActionsLogs from '@/pages/autoActions/autoActionsLogs';
import AutoActionsLogsDetail from '@/pages/autoActions/autoActionsLogs/_id';

// 非認証ページ
import Login from '@/pages/login';
import MasterLogin from '@/pages/master_login';
import LineEntry from '@/pages/line_entry';
import Join from '@/pages/join';
import MailReserve from '@/pages/forms/mail_reserve';
import Issue from '@/pages/issue';
import UpdateEmailExt from '@/pages/update_email_ext';
import Policy from '@/pages/policy';
import FlagSurveyExt from '@/pages/flagsurvey_ext';
import RichMessageExt from '@/pages/forms/richmessage_ext';
import SelectionReserveExt from '@/pages/forms/selection_reserve_ext';
import RemindersExt from '@/pages/forms/reminders_ext';
import LineMessageExt from '@/pages/forms/line_message_ext';
import NoticeExt from '@/pages/forms/notice_ext';
import MyPage from '@/pages/mypage/index';
import LineNotificationVerify from '@/pages/lineNotificationVerify';

import store from '@/store';
import widget from '@/utils/widget';

const NOT_AUTHENTICATED_PAGES = [
  'Login',
  'MasterLogin',
  'LineEntry',
  'Join',
  'MailReserve',
  'Issue',
  'UpdateEmailExt',
  'Policy',
  'FlagSurveyExt',
  'RichMessageExt',
  'SelectionReserveExt',
  'RemindersExt',
  'LineMessageExt',
  'NoticeExt',
  'MyPage',
  'ApplicantPrint',
  'LineNotificationVerify',
];

const routes = [
  {
    path: '/',
    component: Top,
    meta: { breadcrumb: 'トップ', realLink: 'Home' },
    children: [
      {
        path: '',
        redirect: { name: 'Home' },
      },
      {
        path: 'home',
        name: 'Home',
        component: Home,
      },
      {
        path: 'dashboard',
        component: Dashboard,
        children: [
          {
            path: '',
            component: DashboardBasic,
            name: 'DashboardDefault',
            meta: { mode: 0 },
          },
          {
            path: 'basic',
            component: DashboardBasic,
            name: 'DashboardBasic',
            meta: { mode: 0 },
          },
          {
            path: 'entry',
            component: DashboardEntry,
            name: 'DashboardEntry',
            meta: { mode: 1 },
          },
          {
            path: 'recruitment',
            redirect: { name: 'DashboardRecruitment' },
            children: [
              {
                path: 'progress',
                component: () =>
                  import(
                    '@/components/page/dashboard/recruitment/components/DashboardRecruitment'
                  ),
                name: 'DashboardRecruitment',
                meta: { mode: 2 },
              },
              {
                path: 'hiring-analysis',
                component: () => import('@/pages/dashboard/hiringAnalytics'),
                name: 'DashboardHiringAnalysis',
                meta: { mode: 2 },
              },
            ],
          },

          {
            path: 'kind',
            component: DashboardKind,
            name: 'DashboardKind',
            meta: { mode: 3 },
          },
        ],
      },
      {
        path: 'applicants',
        props: true,
        component: Applicants,
        name: 'ApplicantsOverviewDefault',
      },
      {
        path: 'applicants/page/:page',
        component: Applicants,
        name: 'ApplicantsOverview',
        meta: { disabledPageloader: true },
        // eslint-disable-next-line arrow-parens
        props: route => ({
          page: route.params.page ? parseInt(route.params.page, 10) : 1,
        }),
      },
      {
        path: 'applicants/unreadpage',
        component: ApplicantsUnreadPage,
        children: [
          {
            path: '',
            name: 'ApplicantsUnreadPage',
            meta: { mode: 0 },
          },
          {
            path: 'unreadMessages',
            name: 'ApplicantsUnreadMessages',
            meta: { mode: 0 },
          },
          {
            path: 'selectionReserves',
            name: 'ApplicantsSelectionReserves',
            meta: { mode: 1 },
          },
          {
            path: 'enteredApplicants',
            name: 'ApplicantsEnteredApplicants',
            meta: { mode: 2 },
          },
          {
            path: 'uploads',
            name: 'ApplicantsUploads',
            meta: { mode: 3 },
          },
        ],
      },
      {
        path: 'applicants/registration',
        component: ApplicantsRegistration,
        children: [
          {
            path: '',
            name: 'ApplicantsRegistration',
            meta: { mode: 0 },
          },
          {
            path: 'mynavi',
            name: 'ApplicantsRegistrationCsvMynavi',
            meta: { mode: 0 },
          },
          {
            path: 'recnavi',
            name: 'ApplicantsRegistrationCsvRecnavi',
            meta: { mode: 1 },
          },
          {
            path: 'mochica',
            name: 'ApplicantsRegistrationCsvMochica',
            meta: { mode: 2 },
          },
        ],
      },
      {
        path: 'applicants/emailregist',
        component: ApplicantsEmailRegistration,
        name: 'ApplicantsEmailRegistration',
      },
      {
        path: 'applicants/doublecheck',
        component: ApplicantsDoubleCheck,
        name: 'ApplicantsDoubleCheck',
      },
      {
        path: 'applicants/csv',
        component: ApplicantsCsvDownload,
        name: 'ApplicantsCsvDownload',
      },
      {
        path: 'applicants/:id',
        component: ApplicantsId,
        // eslint-disable-next-line arrow-parens
        props: route => ({
          id: route.params.id ? parseInt(route.params.id, 10) : null,
        }),
        name: 'ApplicantDetail',
      },
      {
        path: 'selections',
        component: SelectionsOverview,
        name: 'SelectionsOverview',
      },
      {
        path: 'selections/registration',
        component: SelectionRegistration,
        name: 'SelectionRegistration',
        meta: {
          isRegistration: true,
        },
      },
      {
        path: 'selections/:id',
        component: SelectionDetail,
        name: 'SelectionDetail',
        // eslint-disable-next-line arrow-parens
        props: route => ({
          id: route.params.id ? parseInt(route.params.id, 10) : null,
        }),
      },
      {
        path: 'calendar',
        component: Calendar,
        name: 'Calendar',
      },
      {
        path: 'taggroups',
        component: TagGroups,
        name: 'TagGroups',
      },
      {
        path: 'configs',
        component: Configs,
        children: [
          {
            path: 'personal',
            component: ConfigsPersonal,
            name: 'Configs',
            meta: { mode: 0 },
          },
          {
            path: 'personal',
            component: ConfigsPersonal,
            name: 'PersonalPage',
            meta: { mode: 0 },
          },
          {
            path: 'company',
            component: ConfigsCompany,
            name: 'CompanyPage',
            meta: { mode: 1 },
          },
          {
            path: 'staffs',
            component: ConfigsStaffs,
            name: 'StaffsPage',
            meta: { mode: 2 },
          },
          {
            path: 'auto-actions',
            children: [
              {
                path: '',
                component: AutoActions,
                name: 'AutoActions',
                meta: { mode: 3 },
              },
              {
                path: 'logs',
                component: AutoActionsLogs,
                name: 'AutoActionsLogs',
                meta: { mode: 3 },
              },
            ],
          },
          {
            path: 'entryformSetting',
            component: ConfigsEntryformSetting,
            name: 'EntryformSettingPage',
            meta: { mode: 4 },
          },
          {
            path: 'survey',
            component: ConfigsSurvey,
            name: 'Survey',
            meta: { mode: 5 },
          },
          {
            path: 'fixphrase',
            component: ConfigsFixphrase,
            name: 'FixPhrase',
            meta: { mode: 6 },
          },
          {
            path: 'plan',
            component: ConfigsPlan,
            name: 'Plan',
            meta: { mode: 7 },
          },
          {
            path: 'linkSystem',
            component: ConfigsLinkSystem,
            name: 'LinkSystem',
            meta: { mode: 8 },
          },
        ],
      },
      {
        path: 'configs/auto-actions',
        children: [
          {
            path: ':id',
            component: AutoActionsDetail,
            name: 'AutoActionsDetail',
          },
          {
            path: 'create',
            component: AutoActionsCreate,
            name: 'AutoActionsCreate',
          },
          {
            path: 'create/confirm',
            component: AutoActionsCreate,
            name: 'AutoActionsCreateConfirm',
            beforeEnter: (to, from, next) => {
              if (from.path === '/configs/auto-actions/create') {
                next();
              } else {
                next('/configs/auto-actions/create');
              }
            },
          },
          {
            path: 'edit/:id',
            component: AutoActionsEdit,
            name: 'AutoActionsEdit',
          },
          {
            path: 'edit/:id/confirm',
            component: AutoActionsEdit,
            name: 'AutoActionsEditConfirm',
          },
          {
            path: 'logs/:id',
            component: AutoActionsLogsDetail,
            name: 'AutoActionsLogsDetail',
          },
        ],
      },
      {
        path: 'announce',
        component: Announce,
        name: 'Announce',
      },
      {
        path: 'announce/noticeannounce',
        props: true,
        component: NoticeAnnounce,
        name: 'NoticeAnnounce',
      },
      {
        path: 'announce/selectionannounce',
        props: true,
        component: SelectionAnnounce,
        name: 'SelectionAnnounce',
      },
      {
        path: 'announce/resultannounce',
        props: true,
        component: ResultAnnounce,
        name: 'ResultAnnounce',
      },
      {
        path: 'richmessage',
        props: true,
        component: Richmessage,
        name: 'Richmessage',
      },
      {
        path: 'richmessage/richmessagehistory',
        props: true,
        component: RichmessageHistory,
        name: 'RichmessageHistory',
      },
      {
        path: 'flagsurvey',
        props: true,
        component: Flagsurvey,
        name: 'Flagsurvey',
      },
      {
        path: 'flagsurvey/flagsurveyhistory',
        props: true,
        component: FlagsurveyHistory,
        name: 'FlagsurveyHistory',
      },
    ],
  },
  {
    path: '/admin',
    name: 'Admin',
    component: Admin,
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/master_login',
    name: 'MasterLogin',
    component: MasterLogin,
  },
  {
    path: '/guide',
    name: 'Guide',
    component: Guide,
  },
  {
    path: '/policy',
    name: 'Policy',
    component: Policy,
  },
  {
    path: '/line_entry',
    name: 'LineEntry',
    component: LineEntry,
  },
  {
    path: '/mypage',
    name: 'MyPage',
    component: MyPage,
  },
  {
    path: '/reserves',
    name: 'MailReserve',
    component: MailReserve,
  },
  {
    path: '/join',
    name: 'Join',
    component: Join,
  },
  {
    path: '/issue',
    name: 'Issue',
    component: Issue,
  },
  {
    path: '/flagsurveyext',
    name: 'FlagSurveyExt',
    component: FlagSurveyExt,
  },
  {
    path: '/selection_reserve_ext',
    name: 'SelectionReserveExt',
    component: SelectionReserveExt,
  },
  {
    path: '/richmessage_ext',
    name: 'RichMessageExt',
    component: RichMessageExt,
  },
  {
    path: '/reminders_ext',
    name: 'RemindersExt',
    component: RemindersExt,
  },
  {
    path: '/notice_ext',
    name: 'NoticeExt',
    component: NoticeExt,
  },
  {
    path: '/line_message_ext',
    name: 'LineMessageExt',
    component: LineMessageExt,
  },
  {
    path: '/applicant_print',
    name: 'ApplicantPrint',
    component: ApplicantPrint,
  },
  {
    path: '/update_email_ext',
    name: 'UpdateEmailExt',
    component: UpdateEmailExt,
  },
  {
    path: '/line-notification-messages/verify',
    name: 'LineNotificationVerify',
    component: LineNotificationVerify,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

const beforeVuexFetch = (to, from) => {
  // 画面遷移ごとに事前にfetchが必要な物はここで処理する
  if (to.meta.disabledPageloader !== true) {
    store.dispatch('page/RESET_PAGE');
  } else {
    // 同じ画面の場合、全体ローディング表示は行わない（Pagerによるページ切り替え時を考慮のため）
    if (to.name !== from.name) store.dispatch('page/RESET_PAGE');
  }
};

router.beforeEach(async (to, from, next) => {
  beforeVuexFetch(to, from);
  if (NOT_AUTHENTICATED_PAGES.includes(to.name)) {
    // 非ログインページ
    next();
  } else {
    const res = await store.dispatch('auth/CHECK_LOGIN');
    if (res === true) {
      try {
        store.getters['staff/isAdmin'];
        store.getters['company/isGuided'];
      } catch (e) {
        await store.dispatch('auth/INIT');
      }
      if (
        !(store.getters['staff/isAdmin'] || store.getters['company/isGuided'])
      ) {
        // 非Adminまたは、会社設定ガイドが終わっていない場合の処理
        // 管理者か否か
        if (store.getters['staff/isOwner']) {
          widget.showChatbot();
          widget.initPage();
          // ガイドページにいなければ、ガイドページ飛ばす
          to.name !== 'Guide' ? next({ name: 'Guide' }) : next();
        } else {
          // 管理者でなければログイン画面へ飛ばす
          widget.hideChatbot();
          next({ name: 'Login' });
        }
      } else {
        widget.showChatbot();
        widget.initPage();
        // 通常のユーザか、admin === 2の場合の処理
        to.name === 'Guide' ? next({ name: 'DashboardBaseData' }) : next();
      }
    } else {
      // ログインしていない場合はchatbotを非表示にしてLoginページへ飛ばす
      widget.hideChatbot();
      next({ name: 'Login' });
    }
  }
});

export default router;
